var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "mylist__wrapper" }, [
    _c("dl", { staticClass: "mylist__set" }, [
      _c("dt", [_vm._v("納品希望日：")]),
      _vm._v(" "),
      _c("dd", { on: { click: _vm.popup } }, [
        _c("span", { attrs: { id: "modalOpen" } }, [
          _vm._v(_vm._s(_vm.delivDate)),
        ]),
      ]),
      _vm._v(" "),
      _vm.isShowOrderTotalAmount ? _c("dt", [_vm._v("発注金額：")]) : _vm._e(),
      _vm._v(" "),
      _vm.isShowOrderTotalAmount
        ? _c("dd", [
            _vm._v(_vm._s(_vm._f("number_format")(_vm.orderTotal)) + "円"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("dt", [_vm._v("発注点数：")]),
      _vm._v(" "),
      _c("dd", [_vm._v(_vm._s(_vm.orderTypes) + "点")]),
      _vm._v(" "),
      _c("dt", [_vm._v("発注メモ：")]),
      _vm._v(" "),
      _c("dd", {
        class: { "color-white": !_vm.nl2br(_vm.history.order_memo) },
        domProps: {
          innerHTML: _vm._s(_vm.nl2br(_vm.history.order_memo) || "."),
        },
      }),
      _vm._v(" "),
      _c("dt", [_vm._v("発注日：")]),
      _vm._v(" "),
      _c("dd", [_vm._v(_vm._s(_vm.orderDate))]),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm.history.popup && !_vm.showConfirm
      ? _c(
          "div",
          { staticClass: "modal-wrapper", attrs: { id: "content-modal" } },
          [
            _c("div", { staticClass: "modal__inner--large" }, [
              _c(
                "button",
                {
                  staticClass: "modal__close--small",
                  attrs: { type: "button", id: "content-close" },
                  on: { click: _vm.popup },
                },
                [
                  _c("img", {
                    attrs: { src: "/resource/icon_cross.svg", alt: "閉じる" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("section", { staticClass: "modal__scroll" }, [
                _c("h2", { staticClass: "modal__title" }, [
                  _vm._v("発注日：" + _vm._s(_vm.orderDate)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "modal__item-number" }, [
                  _vm._v("発注点数：" + _vm._s(_vm.orderTypes) + "点"),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "modal__list" },
                  _vm._l(_vm.history.cart, function (item, index) {
                    return _c("li", [
                      _c("span", { staticClass: "modal__list--name" }, [
                        _vm._v(_vm._s(item.product_name) + " "),
                        !Boolean(item.available)
                          ? _c("label", [_vm._v("※取扱停止中")])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal__list--unit" }, [
                        _vm.isShowProductUnitPrice
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("number_format")(item.price)) +
                                  "円 × "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(item.quantity))]),
                      ]),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.isShowOrderTotalAmount
                  ? _c("div", { staticClass: "modal__total" }, [
                      _vm._v("\n          小計：\n          "),
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("number_format")(_vm.orderTotal))),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.hasItemWithNotAvailable
                ? _c(
                    "button",
                    { staticClass: "modal__order", attrs: { type: "button" } },
                    [_vm._v("再発注不可")]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "modal__order",
                      attrs: { type: "button", id: "mylistOrder" },
                      on: { click: _vm.changeModal },
                    },
                    [_vm._v("この内容で再発注")]
                  ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showConfirm
      ? _c(
          "div",
          { staticClass: "modal-wrapper", attrs: { id: "order-modal" } },
          [
            _c("div", { staticClass: "modal__inner--small" }, [
              _c("p", { staticClass: "modal__text" }, [
                _vm._v("この内容でカートを上書きします"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal__button-wrapper" }, [
                _c(
                  "button",
                  {
                    staticClass: "modal__cancel",
                    attrs: { type: "button", id: "orderCancel" },
                    on: { click: _vm.changeModal },
                  },
                  [_vm._v("キャンセル")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "modal__ok",
                    attrs: { type: "button", id: "orderOk" },
                    on: { click: _vm.reOrder },
                  },
                  [_vm._v("OK")]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "mylist__delete", attrs: { type: "button" } },
      [_c("img", { attrs: { src: "img/icon_cross.svg", alt: "削除" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }